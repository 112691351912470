<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.mpais.sample.title')"
			:modelName="modelName"
			:dialogName="mpaisSampleModal"
			:componentHeaderName="'mpais-form-header'"
			:onOk="onOkSampleModal"
			:onShow="onShowSampleModal"
		>
			<template slot="message" slot-scope="data">
					<v-row dense>
						<!-- IDPAIS -->
						<v-col cols="4"/>
						<v-col cols="12">
							<pui-text-field
								:id="`idpais-mpais`"
								v-model="data.modalData.idpais"
								:label="$t('mpais.idpais')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'mpais-modals',
	data() {
		return {
			mpaisSampleModal: 'mpaisSampleAction'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkSampleModal(modalData) {
			const params = {
				attr1: modalData.field1
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.sampleUrl;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.mpais.sampleaction.title');
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					null,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					},
					null,
					params
				);
			});
		},	
		onShowSampleModal(modalData) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
			console.log(modalData);
		}
	}
};
</script>
