<template>
	<div class="pui-form">
		<mpais-modals :modelName="modelName"></mpais-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mpais-form-header
				:modelPk="modelPk"
			></mpais-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDPAIS -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idpais-mpais`"
									v-model="model.idpais"
									:label="$t('mpais.idpais')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- CODLOCODE -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codlocode-mpais`"
									v-model="model.codlocode"
									:label="$t('mpais.codlocode')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NAME -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`name-mtiporesolucion`"
									v-model="model.name"
									:label="$t('mtiporesolucion.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- FECINIVIGENCIA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecinivigencia-mpais`"
									v-model="model.fecinivigencia"
									:label="$t('mpais.fecinivigencia')"
									:disabled="formDisabled"
									toplabel
									time
									required
								></pui-date-field>
							</v-flex>
							<!-- FECFINVIGENCIA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecfinvigencia-mpais`"
									v-model="model.fecfinvigencia"
									:label="$t('mpais.fecfinvigencia')"
									:disabled="formDisabled"
									toplabel
									time
									:min="model.fecinivigencia"
								></pui-date-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- ESCOMUNITARIO -->
							<v-flex xs12 md1>
								<pui-checkbox
									:id="`escomunitario-mpais`"
									:label="$t('mpais.escomunitario')"
									v-model="model.escomunitario"
									true-value="S"
									false-value="N"
								></pui-checkbox>
							</v-flex>
						</v-layout>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mpaisActions from './MpaisActions';
import mpaisModals from './MpaisModals.vue';

export default {
	name: 'mpais-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mpais-modals': mpaisModals
	},
	data() {
		return {
			modelName: 'mpais',
			actions: mpaisActions.formActions
		};
	},
	methods: {
		afterGetData() {
			if (!this.model.escomunitario) {
				this.model.escomunitario = 'N';
			}
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mpais');
		}
	},
	created() {}
};
</script>
